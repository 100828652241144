export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "referto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are using Reverse Proxy, please refer to"])},
        "commonProblems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended Reverse Proxy configuration."])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you confirm to retry ?"])}
      },
      "zh": {
        "referto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您设置了反向代理，请参考"])},
        "commonProblems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuboard 推荐的反向代理配置。"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认重试？"])}
      }
    }
  })
}
